import { type ComponentPropsWithoutRef } from 'react'
import { Icon, Typography } from '@shc/ui'

export interface SearchLocationWaitTimesProps extends ComponentPropsWithoutRef<'div'> {
  waitTime: string | null
}

const SearchLocationWaitTimes = ({
  waitTime,
  className,
  ...props
}: SearchLocationWaitTimesProps) => {
  return (
    <div className={className} {...props}>
      <div className="flex flex-row items-center gap-2">
        <Icon fixedWidth icon="person-walking" className="h-4 text-gray-700 text-sm" />
        <Typography variant="body-semibold" className="text-gray-700">
          Walk-in wait time
        </Typography>
      </div>
      <Typography className="ml-7">{waitTime}</Typography>
    </div>
  )
}

export default SearchLocationWaitTimes
