import { type ComponentPropsWithoutRef } from 'react'
import { saveAspotSummary } from '@lib/utilities/date-utilities'
import { Icon } from '@shc/ui'

export interface LocationSaveaSpotSummaryProps extends ComponentPropsWithoutRef<'div'> {
  timeslot: number | string | Date
}

const LocationSaveaSpotSummary = ({
  timeslot,
  className,
  ...props
}: LocationSaveaSpotSummaryProps) => {
  if (!timeslot) return null
  return (
    <div className={className} {...props}>
      <div className="flex flex-row gap-2 items-start">
        <p>
          <Icon fixedWidth icon="calendar-days" className="text-gray-700" />
        </p>
        <div>
          <div className="font-semibold">Save a spot</div>
          <div className="font-medium">First available {saveAspotSummary(timeslot)}</div>
        </div>
      </div>
    </div>
  )
}

export default LocationSaveaSpotSummary
