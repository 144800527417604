'use client'

import { useState } from 'react'
import clsx from 'clsx'
import { Badge, Button, Container, Icon, Typography } from '@shc/ui'
import {
  Configure,
  useCurrentRefinements,
  useHits,
  useInstantSearch,
  usePagination,
} from 'react-instantsearch'

import LocationSearchResult, {
  type TypePlaceHit,
} from '@app/[locale]/(main)/search/_components/location-search-result'
import { LocationSearchContentLoader } from '@app/[locale]/(main)/search/_components/skeleton-loaders/location-search-loading'
import { useSharedSearchContext } from '@app/[locale]/(main)/search/_contexts/search-context-wrapper'
import EmptyState from '@app/[locale]/(main)/search/_components/empty-state'
import SearchError from '@app/[locale]/(main)/search/_components/search-error'
import SearchHero from '@app/[locale]/(main)/search/_components/search-hero'
import SearchNav from '@app/[locale]/(main)/search/_components/search-nav'
import SearchResultsSummary from '@app/[locale]/(main)/search/_components/search-results-summary'
import SearchAnalyticsTracker from '@app/[locale]/(main)/search/_components/search-analytics-tracker'
import SearchSession from '@app/[locale]/(main)/search/_components/search-session'
import {
  locationFacetConfigMap,
  SearchPromoTagEnum,
} from '@app/[locale]/(main)/search/_utils/constants'
import SearchPromos from '@app/[locale]/(main)/search/_components/search-promos'
import Drawer from '@components/drawer'
import Pagination from '@components/pagination'
import SearchFacetMenu from '@components/search-facet-menu'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import { doScroll } from '@hooks/useCustomScroll'
import { AnalyticsLink } from '@components/analytics'
import { useBuildSearchContext } from '@lib/analytics'

const PlaceResults = () => {
  const { results, status: instantSearchStatus } = useInstantSearch()
  const { items, sendEvent } = useHits<TypePlaceHit>()
  const showLoadState = instantSearchStatus === 'stalled'
  const showNoResultsState = !showLoadState && items.length === 0

  if (showNoResultsState) return

  return (
    <>
      <SearchSession
        indexName={results.index}
        queryId={items[0]?.__queryID ?? 'undefined'}
        namespace="places"
      />
      <section className="flex flex-col gap-8 lg:gap-10">
        <ul className="list-none flex flex-col">
          {items.map((item) => {
            return (
              <li key={item.objectID} className="py-8 border-t last:border-b">
                <LocationSearchResult hit={item} sendEvent={sendEvent} />
              </li>
            )
          })}
        </ul>
      </section>
    </>
  )
}

// eslint-disable-next-line @next/next/no-async-client-component
export default function SearchLocationIndex() {
  /* Algolia hooks */
  const { results, status: instantSearchStatus } = useInstantSearch()
  const { nbHits, currentRefinement, nbPages, refine: refinePagination } = usePagination()
  const currentRefinements = useCurrentRefinements()
  const snowplowSearchContext: SPContext = useBuildSearchContext()

  /* Contexts */
  const { selectedLocation } = useSharedSearchContext()

  /* State variables */
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false)

  /* Derived variables */
  const facets = results.renderingContent?.facetOrdering?.facets?.order ?? []
  const showErrorState = instantSearchStatus === 'error'
  const showLoadState = instantSearchStatus === 'stalled'
  // see Handling no results in Algolia: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-display/react/#handling-no-results
  const showNoResultsState = !results.__isArtificial && results.nbHits === 0
  const showResults = !showErrorState && !showNoResultsState
  const showPagination = showResults && nbPages > 1
  const showRefinements = currentRefinements.items?.length > 0

  const { track } = useAnalytics()

  // Handlers
  const onPageChange = (page: number) => {
    refinePagination(page)
    doScroll(0)
  }
  return (
    <>
      {/* Algolia InstantSearch configuration */}
      <Configure
        clickAnalytics={true}
        {...(selectedLocation
          ? {
              aroundLatLngViaIP: false,
              aroundLatLng: `${selectedLocation?.lat},${selectedLocation?.lng}`,
            }
          : { aroundLatLngViaIP: true })}
      />

      <Drawer
        isDrawerOpen={isFilterMenuOpen}
        setIsDrawerOpen={setIsFilterMenuOpen}
        activePathname="/search/locations"
        label="Search filters"
        labelledBy="search-filter-button"
        eventPrefix="filter_drawer">
        <div
          className="flex flex-row items-start justify-between border-b mb-5"
          data-testid="menu-drawer-header">
          <Typography variant="h5" className="w-full pb-5 text-left">
            Filters
          </Typography>
        </div>
        <SearchFacetMenu facets={facets} facetConfigMap={locationFacetConfigMap} />
      </Drawer>

      <SearchAnalyticsTracker />
      <SearchHero title="Locations" searchPlaceholderText="Search by location name or keyword" />
      <SearchNav />

      {/* Results Container */}
      <Container className="py-8 xl:py-15 flex gap-x-10" data-search="results-container">
        {/* LEFT COLUMN - FACETS*/}
        <div className="hidden xl:flex flex-col pr-4 w-[306px] min-w-[306px] sticky top-20 max-h-[calc(100vh-5rem)] overflow-y-auto pl-3 -ml-3">
          <SearchFacetMenu facets={facets} facetConfigMap={locationFacetConfigMap} />
        </div>

        {/* RIGHT COLUMN - RESULTS */}
        <div className={clsx('flex flex-col flex-grow', showResults && 'gap-5 md:gap-8')}>
          {/* RESULTS SUMMARY, SORT+FILTER BUTTON */}
          <div className="flex flex-col-reverse justify-start xl:flex-row xl:justify-between gap-5 md:gap-8">
            {/* results summary */}
            {showResults && <SearchResultsSummary />}

            {/* Filter button - hide on xl */}
            {(showResults || showRefinements) && (
              <Button
                id="search-filter-button"
                aria-label="Open search filters"
                className={clsx('xl:hidden', showNoResultsState && 'mb-5 md:mb-8')}
                size="sm"
                width="md"
                startDecorator={<Icon icon="sliders-simple" />}
                endDecorator={
                  currentRefinements.items.length > 0 && (
                    <Badge>
                      {currentRefinements.items.reduce(
                        (total, item) => total + item.refinements.length,
                        0
                      )}
                    </Badge>
                  )
                }
                onClick={() => {
                  setIsFilterMenuOpen(true)

                  // button click event
                  track({
                    event: { name: 'component_click', data: {} },
                    contexts: [{ name: 'component', data: { component_text: 'Filters' } }],
                  })

                  // modal open event
                  track({
                    event: { name: 'modal_open', data: {} },
                    contexts: [
                      {
                        name: 'modal',
                        data: { modal_name: 'Locations search filter menu' },
                      },
                    ],
                  })
                }}>
                Filters
              </Button>
            )}
          </div>

          {/* ERROR */}
          {showErrorState && <SearchError />}

          {/* LOADING */}
          {showLoadState && <LocationSearchContentLoader />}

          {/* NO RESULTS */}
          {showNoResultsState && (
            <EmptyState data-testid="no-results-state">
              <Typography variant="h2">No locations matched your search term</Typography>
              <Typography variant="body">
                Adjust the filters, try a different search term or select another search category.
              </Typography>
              <Typography variant="body">
                Need help?&nbsp;
                <AnalyticsLink
                  href="/contact-us"
                  snowplow={{
                    event: { name: 'contact_us_click', data: { contact_type: 'contact' } },
                    contexts: [snowplowSearchContext],
                  }}>
                  Contact us
                </AnalyticsLink>
              </Typography>
            </EmptyState>
          )}

          {/* PROMOS & RESULTS */}
          <div className="flex flex-col">
            {/* PROMOS */}
            <SearchPromos searchPromoTag={SearchPromoTagEnum.SearchPromoPlacesTag} />

            {/* RESULTS */}
            <PlaceResults />
          </div>

          {/* PAGINATION */}
          {showPagination && (
            <Pagination
              className="" // Need empty classname to let flex control spacing
              totalItems={Math.min(nbPages * results.hitsPerPage, nbHits)}
              itemsPerPage={results.hitsPerPage}
              currentPageNumber={currentRefinement + 1}
              pageClickFunction={onPageChange}
              pageIndexStart={0}
            />
          )}
        </div>
      </Container>
    </>
  )
}
